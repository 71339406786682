import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';
import { useAuthContext } from '../../../auth/useAuthContext';
import { filterNavConfigByRole } from '../../../utils/navConfig';
// ----------------------------------------------------------------------
export default function NavMini() {
    const { user } = useAuthContext();
    const filteredNavConfig = filterNavConfigByRole(navConfig, user?.roles[0].code, user?.roles);
    return (_jsxs(Box, { component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD_MINI },
        }, children: [_jsx(NavToggleButton, { sx: {
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                } }), _jsxs(Stack, { sx: {
                    pb: 2,
                    height: 1,
                    position: 'fixed',
                    width: NAV.W_DASHBOARD_MINI,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    ...hideScrollbarX,
                }, children: [_jsx(Logo, { sx: { mx: 'auto', my: 2 } }), filteredNavConfig && _jsx(NavSectionMini, { data: filteredNavConfig })] })] }));
}
